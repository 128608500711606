<template>
  <div>
    <el-card class="top" >
      <el-row>
        <el-col :span="2">
          <div class="logo"><img src="~pub/img/11.png" alt="">
              <p>{{partyName}}</p>
          </div>
        </el-col>
        <el-col :span="21"> 
          <el-steps :active="active" align-center finish-status="success" >
            <el-step title="V1" description="累计充值1000000"></el-step>
            <el-step title="V2" description="累计充值5000000"></el-step>
            <el-step title="V3" description="累计充值10000000"></el-step>
            <el-step title="V4" description="累计充值100000000"></el-step>
            <el-step title="V5" description="累计充值500000000"></el-step>
            <el-step title="V6" description="累计充值1000000000"></el-step>
          </el-steps>
      </el-col>
      </el-row>
      <el-row class="top" style="margin-top:30px">
        <el-tabs v-model="activeName" type="card" @tab-click="handleClick">
            <el-tab-pane label="用户管理" name="first">企业信息</el-tab-pane>
            <el-tab-pane label="配置管理" name="second">合同信息</el-tab-pane>
            <el-tab-pane label="角色管理" name="third">联系信息</el-tab-pane>
        </el-tabs>
         <!-- <el-radio-group v-model="tabPosition" style="margin-bottom: 30px;">
            <el-radio-button label="用户管理1">用户管理</el-radio-button>
            <el-radio-button label="配置管理1">配置管理</el-radio-button>
            <el-radio-button label="角色管理1">角色管理</el-radio-button>
          </el-radio-group> -->
      </el-row>
     
    </el-card>
  </div>
</template>

<script>
export default {
  data() {
    return {
      active:2,
      partyName:"",
      tabPosition: 'left'
    };
  },
  mounted () {
    this.partyName=localStorage.getItem('partyName')
      
  },
  methods: {
     handleClick(tab, event) {
        console.log(tab, event);
      }
    }
};
</script>

<style  scoped>
.logo img{
  height: 70px;
  width: 70px;
  border-radius: 50%;
  overflow: hidden;
  margin-left: 30px;
}
.top{
  margin-top: -10px
}
</style>


